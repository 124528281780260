import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {DeleteRequest, GetRequest, PostRequest} from "./methods";
import {
  INotificationsListNotificationsHttp
} from "@prestinly/core-lib/lib/types/notifications/list.notifications.http.interface";
import {TNotification} from "@prestinly/core-lib/lib/types/notifications/notification.type";
import {NotificationUnread} from "@prestinly/core-lib/lib/enums/notifications/notification-unread.enum";
import {ENotificationType} from "@prestinly/core-lib/lib/enums/notifications/notification-type.enum";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.notificationsUrl;
  }

  async list(dto: {
    page: number,
    limit: number,
    read: NotificationUnread[],
    types: ENotificationType[],
  }): Promise<INotificationsListNotificationsHttp> {
    const request = new PostRequest(this.http, `${this.apiUrl}/list`, dto,);
    return await request.getResponse();
  }

  async read(list: { notificationsIds: string[] }): Promise<any> {
    const request = new PostRequest(this.http, `${this.apiUrl}/read`, list,);
    return await request.getResponse();
  }

  async details(id: string): Promise<TNotification> {
    const request = new GetRequest(this.http, `${this.apiUrl}/${id}`,);
    return await request.getResponse<TNotification>();
  }

  async delete(id: string): Promise<any> {
    const request = new DeleteRequest(this.http, `${this.apiUrl}/${id}`,);
    return await request.getResponse();
  }
}
