"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENotificationType = void 0;
var ENotificationType;
(function (ENotificationType) {
    ENotificationType["SYSTEM"] = "SYSTEM";
    ENotificationType["SUBSCRIPTION"] = "SUBSCRIPTION";
    ENotificationType["CONTENT"] = "CONTENT";
    ENotificationType["PURCHASES"] = "PURCHASES";
    ENotificationType["TAGS"] = "TAGS";
})(ENotificationType || (exports.ENotificationType = ENotificationType = {}));
