import {Injectable} from '@angular/core';
import {NotificationsService} from "../api/notifications.service";
import {BehaviorSubject, from, Observable} from "rxjs";
import {NotificationUnread} from "@prestinly/core-lib/lib/enums/notifications/notification-unread.enum";
import {ENotificationType} from "@prestinly/core-lib/lib/enums/notifications/notification-type.enum";
import {
  INotificationsListNotificationsHttp
} from "@prestinly/core-lib/lib/types/notifications/list.notifications.http.interface";
import {INotificationsUnread} from "@prestinly/core-lib/lib/types/notifications/unread.interfaces";

@Injectable({
  providedIn: 'root'
})
export class NotificationsRepositoryService {

  public notificationsUpdateSubject = new BehaviorSubject<boolean>(true);
  notificationsSubject$ = this.notificationsUpdateSubject.asObservable();

  public unreadSubject = new BehaviorSubject<INotificationsUnread | null>(null);
  unreadSubject$ = this.unreadSubject.asObservable();

  constructor(private api: NotificationsService) {}


  unread() {
    this.api.list({
      page: 1,
      limit: 50,
      read: [NotificationUnread.UNREAD],
      types: [
        ENotificationType.TAGS,
        ENotificationType.SYSTEM,
        ENotificationType.CONTENT,
        ENotificationType.PURCHASES,
        ENotificationType.SUBSCRIPTION
      ],
    }).then(data => {
      this.unreadSubject.next(data.unread);
      this.notificationsUpdateSubject.next(true);
    });
  }

  list(dto: {
    page: number,
    limit: number,
    type: ENotificationType | null,
  }): Observable<INotificationsListNotificationsHttp> {
    return from(this.api.list({
      page: dto.page,
      limit: dto.limit,
      read: [NotificationUnread.READ, NotificationUnread.UNREAD],
      types: dto.type === null ?
        [
          ENotificationType.TAGS,
          ENotificationType.SYSTEM,
          ENotificationType.CONTENT,
          ENotificationType.PURCHASES,
          ENotificationType.SUBSCRIPTION
        ] : [dto.type],
    }));
  }

  async read(ids: string[]) {
    await this.api.read({notificationsIds: ids});
  }

  async delete(id: string): Promise<any> {
    await this.api.delete(id);
  }
}
